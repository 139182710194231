import React, { useContext, useEffect, useState } from "react";
import classNames from 'classnames';
import { Link, useStaticQuery, graphql } from "gatsby";
import { Nav, Container, Col, Row, Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";

import PopupMenu from "../components/Header/Sections/PopupMenu";
/**
 * Assest
 */
import $ from "jquery";
import Layout from "../components/layout";
import SEO from "../components/seo";
import '../scss/_sitemap.scss';


function ContextAwareToggle({ children, eventKey, callback }) {
  useEffect(() => {
      $(".open-third-level").hide();

      $(".thrid-level").each(function(){
          var thirdlevel = $(this).find("li");
          //console.log('aaaaaaaaa',thirdlevel.length)
          var ifthirdlevel = thirdlevel.length
          if(ifthirdlevel > 0) {
              $(this).parent().addClass('has-dropdown');
          }
      });
      $(".has-dropdown .open-third-level").show();

        //toggle the component with class thrid-level
    $(".open-third-level").click(function() {
      if ($('.thrid-level').is(':visible')) {
        $(".thrid-level").hide();
        $(".plusminus").text('+');
      }
      if ($(this).next(".thrid-level").is(':visible')) {
        $(this).next(".thrid-level").hide();
        $(this).children(".plusminus").text('+');
      } else {
        $(this).next(".thrid-level").stop().slideToggle('slow');

        $(this).children(".plusminus").text('-');
      }
    });
  })
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
      );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
      <button
      type="button"
      className={classNames('toggle-button', {'active': isCurrentEventKey})}
      onClick={decoratedOnClick}
      >toggle-button</button>
      );
}

const Sitemap = (props) => {
    const [search, setSearch] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [open, setOpen] = useState(false);
    const [menu_heading, setMenuHeading] = useState(false);

    const openHam = () => {
      setOpen(!open);
    };

    const openSearch = (menu_title) => {
      setSearch(!search);
      handlerClick();
      setMenuHeading(menu_title)
    };
    const closeSearch = () => {
      setSearch(!search);
    };

    const [state, setState] = React.useState({
      showMenu: false
    })
  
    const handlerClick = () => {
  
        //console.log('okkk')
        setState({ ...state, showMenu: !state.showMenu })
        if (state.showMenu == true) {
            //console.log('yes')
            document.body.className = '';
  
        }
        else {
            document.body.classList.add("overflow-hidden");
        }
    }

    const data = useStaticQuery(graphql`
    query SitemapQuery {
        glstrapi {
          allMenus(sort: "Sort_Order:ASC"){
            id
            Primary_URL
            Secondary_URL
            Label
            Show_Header_Menu
            Show_Footer_Menu
            Show_Popup_Menu
            Sort_Order
            Link_Type
    
            Main_Parent {
              Label
              Primary_URL
              Secondary_URL
              id
            }
            
            Secondary_Menu {
              Primary_URL
              Secondary_URL
              id
              Label
            }

            nested_menus {
              Primary_URL
              Secondary_URL
              id
              Label
            }
          }
        }
    }`
    );

    //console.log('node', data.allStrapiAllMenus.edges);

    var menu_data = data.glstrapi.allMenus;

    // console.log('data_menu', menu_data);

    return (

        <Layout Layout="Without_Banner" classNames={"search-header person-header"} >
            <SEO title={"Sitemap"} description={"Sitemap"} />

            <div className="site-map">
              <Container>
                <Row>
                <Accordion>
                    <ul className="parent-menu">
                    {
                        menu_data && Object.keys(menu_data).filter(key => menu_data[key].Show_Header_Menu === true).map((key, index) => {

                          var get_submenu = Object.keys(menu_data).filter(key2 => (menu_data[key2].Main_Parent !== null && menu_data[key2].Main_Parent.id === menu_data[key].id));

                          return <>
                            <li className={menu_data[key].Label === "About" ? 'no_column' : ''}>
                              {
                                  menu_data[key].Link_Type === "Megamenu" ? 
                                  <a href="javascript:;" onClick={()=>{openSearch(menu_data[key].Label)}}>{menu_data[key].Label}</a>
                                  : menu_data[key].Link_Type === "Dropdown_menu" ? 
                                  <a href="javascript:;">{menu_data[key].Label}</a>
                                  : <Link to={`/${menu_data[key].Secondary_URL ? menu_data[key].Secondary_URL : menu_data[key].Primary_URL}`}>{menu_data[key].Label}</Link>
                              } 

                              <ContextAwareToggle eventKey={menu_data[key].id} />
                              <Accordion.Collapse eventKey={menu_data[key].id}>
                                <Nav className="dropdown-list">
                                    {
                                      menu_data[key].Label.toLowerCase() === "what we do" ?

                                      <ul className="primary_submenu">
                                        {menu_data && menu_data.length > 0 && menu_data.filter(item => item.Show_Popup_Menu === true && item.Label !== "Home" && item.Main_Parent === null).map((item, i) => {
                                            return(
                                                <li>
                                                    <Link to={'/'+item.Primary_URL}>{item.Label}</Link>

                                                    <ul className="secondary_submenu">
                                                    {
                                                        menu_data && Object.keys(menu_data).filter(key => menu_data[key].Main_Parent !== null && menu_data[key].Secondary_Menu === null && item.id === menu_data[key].Main_Parent.id).map((key, index) =>{

                                                          var getSubMenu = menu_data && Object.keys(menu_data).filter(key2 => (menu_data[key2].Main_Parent !== null && menu_data[key2].Secondary_Menu !== null && menu_data[key2].nested_menus === null && menu_data[key2].Secondary_Menu.id === menu_data[key].id));

                                                            return(
                                                                <li className={'third_level_items'}>

                                                                    <Link to={'/'+item.Primary_URL+'/'+menu_data[key].Primary_URL}>{menu_data[key].Label}</Link>

                                                                    {getSubMenu.length > 0 && (
                                                                        <ul className="third_submenu">
                                                                            {getSubMenu.map((sub_key, i) => {

                                                                                var getSubMenu2 = menu_data && Object.keys(menu_data).filter(key3 => (menu_data[key3].Main_Parent !== null && menu_data[key3].Secondary_Menu !== null && menu_data[key3].nested_menus !== null && menu_data[key3].nested_menus.id === menu_data[sub_key].id));

                                                                                return(
                                                                                <li>
                                                                                    <Link to={'/'+item.Primary_URL+'/'+menu_data[sub_key].Secondary_Menu.Primary_URL+'/'+menu_data[sub_key].Primary_URL}>{menu_data[sub_key].Label}</Link>

                                                                              {getSubMenu2.length > 0 && (
                                                                                <ul className="third_submenu">
                                                                                    {getSubMenu2.map((sub_key2, i) => {

                                                                                        return(
                                                                                        <li>
                                                                                            <Link to={'/'+item.Primary_URL+'/'+menu_data[sub_key2].Secondary_Menu.Primary_URL+'/'+menu_data[sub_key2].nested_menus.Primary_URL+'/'+menu_data[sub_key2].Primary_URL}>{menu_data[sub_key2].Label}</Link>
                                                                                        </li>
                                                                                        )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                                )}
                                                                                </li>
                                                                                )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    )}
                                                                    
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                </li>
                                            )
                                          })
                                        }
                                      </ul>
                                      :
                                      <ul className="primary_submenu">
                                        {get_submenu.map((key2, index) => {
                                            return(
                                                <li>
                                                    <Link to={menu_data[key2].Secondary_URL ? menu_data[key2].Secondary_URL : `/${menu_data[key].Primary_URL}/${menu_data[key2].Primary_URL}`}>{menu_data[key2].Label}</Link>
                                                </li>
                                            )
                                          })
                                        }
                                      </ul>
                                    }
                                </Nav>
                              </Accordion.Collapse>                                
                            </li>
                          </>
                        })
                    }
                    </ul>

                </Accordion>
                </Row>
            </Container>
            </div>

            {
              search && 
              <PopupMenu closeSearch={closeSearch} handlerClick={handlerClick} menu_heading={menu_heading} search={search} open={open} menu_data={menu_data} />
            }

        </Layout>
        

    )
}

export default Sitemap
